import { type FC } from 'react'
import { MarketingPreferences } from '~/components'
import { MTDHeader } from '~/components/MTDHeader'

export const Preferences: FC = () => {
  return <div>
    <MTDHeader />
    <MarketingPreferences />
  </div>
}
