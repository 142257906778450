import { Link } from 'react-router-dom'
import styles from './index.module.scss'

const Footer = () => {
  return <footer className={`px-4 py-6 column ${styles.footer}`}>
    <div className="flex-wrap justify-between row">
      <div className={`row flex-wrap stack mb-6 ${styles.nav}`}>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/cookie-preferences">Cookie Preferences</Link>
        <Link to="/faqs">About Us</Link>
        <Link to="/faqs">FAQs</Link>
        <Link to="/contact">Contact Us</Link>
        <Link to="/terms-and-conditions">Terms & Conditions</Link>
      </div>
      <div className={`${styles.copyright} mb-6`}>
        © Copyright {new Date().getFullYear()} MyTenDays. All rights reserved.
      </div>
    </div>
    <div className="flex-wrap justify-start row stack-large">
      <div className={`text-xs ${styles.smallprint}`}>
        Shamaazi trading as MyTenDays. <Link to="/privacy-policy">
        Privacy Statement
      </Link>, Company Registration No: 10622013.
        Head Office: 6-8 Great Eastern Street, London, United Kingdom, EC2A 3NT, United Kingdom. Disclaimer: Shamaazi is not
        affiliated with any external web sites. Shamaazi is not responsible for the content of external internet
        sites and any links from external web sites to this site do not constitute an endorsement of that site
        by Shamaazi.
      </div>
    </div>
  </footer>
}

export default Footer

export const FooterLayout = ({ children }) => {
  return <div className={styles.mtdLayout}>
    {children}
    <Footer />
  </div>
}
