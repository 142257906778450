import { type FC } from 'react'
import { Check } from '@lib/components'

export enum CheckoutStep {
  Charity = 1,
  Causes,
  Payment
}

export const CheckoutProgress: FC<{ currentStep: CheckoutStep }> = ({ currentStep }) => {
  function getStep (step: CheckoutStep, label: string): JSX.Element {
    return <li className="flex items-center justify-center">
      <div
        className={`${currentStep === step ? 'bg-mtd-blue' : 'bg-mtd-gray-200'} w-6 h-6 text-sm sm:w-8 sm:h-8 sm:text-md rounded-full flex justify-center items-center text-white`}>
        {currentStep > step ? <Check /> : step}
      </div>
      <span className={`${currentStep >= step ? 'text-mtd-blue' : 'text-mtd-gray-200'} hidden sm:block ml-4`}>{label}</span>
    </li>
  }

  return <ol className="sticky top-0 z-30 w-full py-3 font-medium bg-white border-b-4 grid grid-cols-4 sm:py-4 border-mtd-gray-200">
    {getStep(CheckoutStep.Charity, 'Charity')}
    {getStep(CheckoutStep.Causes, 'Causes')}
    {getStep(CheckoutStep.Payment, 'Pay')}
  </ol>
}
