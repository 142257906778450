import world from './world.svg'
import hand from './hand.svg'
import calendar from './calendar.svg'
import styles from './index.module.scss'
import { Page } from '@lib/components'

export const Explainer = () => {
  return <Page className="px-4 my-20 md:mt-20 md:mb-14 text-center column items-center stack">
    <h2 className="heading-lg md:pb-7">How it works</h2>
    <div className="row flex-wrap justify-around">
      <div className={`column mb-6 stack items-center ${styles.item}`}>
        <img src={world} alt="world" />
        <div className={styles.circle}>1</div>
        <h3 className="heading-sm">Pick your split</h3>
        <p>Split your donation equally on the 10 days, or top-up more on Arafah.</p>
      </div>
      <div className={`column mb-6 stack items-center ${styles.item}`}>
        <img src={hand} alt="hand" />
        <div className={styles.circle}>2</div>
        <h3 className="heading-sm">Pick your charity</h3>
        <p>We bring you world-leading charities to choose from.</p>
      </div>
      <div className={`column mb-6 stack items-center ${styles.item}`}>
        <img src={calendar} alt="calendar" />
        <div className={styles.circle}>3</div>
        <h3 className="heading-sm">Pick your causes</h3>
        <p>Donate to causes you believe in, including the Zakat-eligible projects.</p>
      </div>
    </div>
  </Page>
}
