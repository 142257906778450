import { isCampaignOpen } from '~/service/activeCampaign'
import { Explainer } from '~/components/Explainer'
import { EmailSubscriber } from '~/components'
import { type FC } from 'react'
import SplitMTD from '~/components/SplitMTD'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { MytennightsTestId } from '@lib/testing'
import { type CharityCampaign, getCharityFromDonation, gmErrorMessage, useCheckoutPlan } from '@shamaazi/mytennights'

export const HomeContent: FC<{ nextPage: string }> = ({ nextPage }) => {
  const { chainCode } = useParams<{ chainCode?: string }>()
  const { plan, setPlan } = useCheckoutPlan()
  const { error: parentCharityError } = useQuery<CharityCampaign | '' | undefined, Error>(
    ['parentCharity', chainCode],
    async () => chainCode && await getCharityFromDonation(chainCode), {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        setPlan({ ...plan, chainCode: chainCode ?? '', chainCodeCharity: data })
      },
      onError: () => {
        setPlan({ ...plan, chainCode: '' })
      }
    })
  return <div>
    <div className="flex flex-col space-y-10 items-center pt-20 pb-5 half-blue h-2/3 md:pb-20">
      <h1 className={'text-center text-4xl md:text-5xl font-bold text-white mt-10'}>Most beloved days of Allah</h1>
      {isCampaignOpen() && <GMErrorMessage error={parentCharityError} chainCode={chainCode}/>}
      {isCampaignOpen() && <SplitMTD nextPage={nextPage} />}
      {!isCampaignOpen() && <div className={'min-w-screen bg-mtd-blue-100 text-white w-full py-2 flex flex-col items-center px-2'}>
        <p>Get a reminder to automate your donations next Hajj</p>
        <EmailSubscriber />
      </div>}
    </div>
    <Explainer />
  </div>
}

const GMErrorMessage: FC<{ error: Error | null, chainCode?: string }> = ({ error, chainCode }) => {
  if (error && chainCode) return <p data-test-id={MytennightsTestId.givematchErrorMessage} className="mt-5 w-11/12 sm:w-3/4 rounded font-medium text-center text-mtn-red ">{gmErrorMessage}</p>
  return <></>
}
