import { type FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Header } from '@lib/legacycomponents'
import { useCheckoutPlan, MTDtracking } from '@shamaazi/mytennights'
import mtdLogo from '~/assets/mtdLogo.svg'
import { isWidget } from '@lib/services'

import { useAuth } from '@lib/hooks'
import { HeaderTestIds } from '@lib/testing'

// connected version to use by default
export const MTDHeader: FC<{ overlay?: boolean }> = ({ overlay = false }) => {
  const { plan } = useCheckoutPlan()
  const { user } = useAuth()
  const navigate = useNavigate()

  const onLogout = (): void => {
    navigate(homeLocation)
    MTDtracking.logOut(user!.email)
  }

  const homeLocation = isWidget ? `/${plan?.charity?.charity_id}` : '/'
  const home = <Link className="link" to={homeLocation}
                     onClick={() => MTDtracking.home(user?.email)}>
    <img src={mtdLogo} alt="My Ten Days" className="mx-4 w-48" />
  </Link>

  return <Header headerStyle={'header-style'} onLogout={onLogout} overlay={overlay} home={home} showNavigation={!isWidget}>
    <Link
      data-test-id={HeaderTestIds.myProfile}
      className="link" to="/account/schedules" onClick={() => {
        MTDtracking.myProfile(user?.email)
      }
    }>My Profile</Link>
  </Header>
}
