import { type FC } from 'react'
import { MTDHeader } from '~/components/MTDHeader'
import { HomeContent } from '~/components/Home/HomeContent'

export const Home: FC<{ nextPage: string }> = ({ nextPage }) => {
  return <>
    <MTDHeader overlay />
    <HomeContent nextPage={nextPage} />
  </>
}
