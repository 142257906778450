import { type FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@lib/components'
import { useLocalStorage } from '@lib/hooks'
import { gaSnippet } from '~/ga'
import { CustomerIO, MixpanelInstance } from '@lib/services'
import { ComponentsTestId } from '@lib/testing'

export const CookieBanner: FC = () => {
  const [hasAccepted, setHasAccepted] = useLocalStorage<boolean | null>('cookie-banner', null)

  useEffect(() => {
    if (hasAccepted ?? false) {
      gaSnippet()
      MixpanelInstance.init('d39731b810cc86c55a978ee0c30bab2d', '01d418ba484328018f1bc609ce849fd1')
      CustomerIO.enable()
    }
  }, [hasAccepted])

  return <>
    {hasAccepted === null && <div className="sticky bottom-0 p-4 bg-mtd-blue">
      <div className="items-center mx-auto max-w-7xl md:flex">
        <p className="flex-1 text-xs text-white sm:text-sm">
          We use cookies to improve your experience on our site. For more information click <Link className="font-medium underline text-white" to="/privacy-policy">here</Link>.
        </p>
        <div className="my-4 h-1 border-t border-mtd-gray-300 md:hidden" />
        <div className="flex justify-center items-center">
          <Button
            data-test-id={ComponentsTestId.acceptCookies}
            variant="mtd-outline"
            className="mx-4"
            onClick={() => setHasAccepted(true)}>
            OK
          </Button>
        </div>
      </div>
    </div>}
  </>
}
