import { type FC, useState } from 'react'
import mtdLogo from '~/assets/mtdLogo.svg'
import { type CharityCampaign } from '@shamaazi/mytennights'
import { EmailSubscriber } from '~/components'
import { isCampaignOpen } from '~/service/activeCampaign'
import { Explainer } from '~/components/Explainer'
import SplitMTD from '~/components/SplitMTD'

const CharityLogo: FC<{ charity: CharityCampaign | undefined }> = ({ charity }) => {
  const [showFallback, setShowFallback] = useState(false)

  return <>
    {showFallback && <h1 className="text-2xl font-bold text-center md:text-3xl text-mtd-blue-900">{charity?.charity_name}</h1>}
    {!showFallback && <img
      src={`/assets/charities/${charity?.charity_id ?? ''}/logo.svg`}
      alt={charity?.charity_name}
      className="max-w-sm px-8 mx-auto"
      onError={() => {
        if (!showFallback) {
          setShowFallback(true)
        }
      }} />}
  </>
}

interface Props {
  loading: boolean
  isError: boolean
  charity: CharityCampaign | undefined
  nextPage: string
  notFound: boolean
}

const Charity: FC<Props> = ({ loading, isError, charity, nextPage, notFound }) => {
  if (loading) {
    return <h2 className="heading-lg">Loading...</h2>
  }

  if (isError && !notFound) {
    return <h2 className="heading-lg">Error</h2>
  }

  return <main>
    <img src={mtdLogo} alt="My Ten Days" className="absolute mt-6 ml-4 w-48" />
    <div className="flex flex-col min-h-screen">
      <div className="space-y-10 items-center pt-20 half-blue">
        <div className="items-center max-w-4xl mx-auto my-6 text-center column">
          <h1 className={'heading-xl text-center'}>Most beloved days of Allah</h1>
          <div className={'my-5'}>
            <CharityLogo charity={charity} />
            {notFound && <h3 className="heading-xl">We are sorry but this charity is not available</h3>}
          </div>
            {isCampaignOpen() && <SplitMTD nextPage={nextPage}/>}
          {!isCampaignOpen() && <div className={'min-w-screen bg-mtd-blue-100 text-white w-full py-2 flex flex-col items-center px-2'}>
            <p>Get a reminder to automate your donations next Hajj</p>
            <EmailSubscriber />
          </div>}
        </div>
      </div>
      <Explainer />
    </div>
  </main>
}

export default Charity
