import { type FC } from 'react'
import { Campaign, SplitForm, SplitType, MTDtracking } from '@shamaazi/mytennights'

const MTDSplit: FC<{ nextPage: string }> = ({ nextPage }) => {
  const splitTypes = [SplitType.Equally, SplitType.MoreArafah]
  const styles = {
    textColor: 'text-mtd-blue-700',
    initialButtonStyles: 'bg-mtd-blue-700 shadow-xl',
    selectedButtonStyles: 'bg-gradient-to-r from-mtn-blue-600 to-mtn-blue-500 shadow-inner'
  }
  return <SplitForm variant={Campaign.mtd} nextPage={nextPage} splitTypes={splitTypes} styles={styles} tracking={MTDtracking} />
}

export default MTDSplit
