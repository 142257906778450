import { MTDHeader } from '~/components/MTDHeader'
import { LoginForm, Page } from '@lib/components'
import { MTDtracking } from '@shamaazi/mytennights'
import { type FC } from 'react'

export const LoginPage: FC = () => {
  return <main>
    <MTDHeader />
    <Page skinny className="px-4 my-6 column stack">
      <h1 className="heading-lg">Login</h1>
      <p>Already made a donation?<br />Login to check your payment schedule or your preferences.</p>

      <LoginForm
        className="mt-8"
        fontVariant="mtn"
        buttonVariant="mtn-primary"
        trackingSignInEmail={(e) => MTDtracking.signInEmail(e)}
        trackingSignInCode={(e) => MTDtracking.signInCode(e)} />
    </Page>
  </main>
}
