import { type FC, useState } from 'react'
import { MytennightsTestId } from '@lib/testing'

const CHARITY_COLOURS: Record<string, string> = {
  'islamic-relief-uk': '#009fe3',
  'orphans-in-need-uk': '#009fe3',
  'penny-appeal-uk': '#f57920',
  'read-foundation-uk': '#4db746',
  'national-zakat-foundation-uk': '#c9333a',
  'human-relief-uk': '#191919',
  'islamic-help-uk': '#232051',
  'multi-charity-uk': '#5ba8d0',
  'multi-charity-au': '#5ba8d0',
  'graham-layton-trust-uk': '#00ac9c',
  'muslim-aid-uk': '#7dab56',

  'islamic-relief-ca': '#009fe3',
  'penny-appeal-ca': '#dc751d',
  'naseeha-ca': '#e5b406',
  'haya-helps-ca': '#32a3e5',
  'nisa-helpline-ca': '#d45c94',
  'national-zakat-foundation-ca': '#c9333a',
  'multi-charity-ca': '#5ba8d0',

  'umr-us': '#d3537a',
  'penny-appeal-us': '#f57920',
  'islamic-relief-us': '#009fe3',
  'naseeha-us': '#e5b406',
  'charity-water-us': '#242424',
  'multi-charity-us': '#5ba8d0',

  'islamic-relief-za': '#009fe3',

  'charity-right-au': '#cf266e',
  'penny-appeal-au': '#f57920',
  'national-zakat-foundation-au': '#c9333a'
}

const additionalColors = [
  'bg-mtd-violet',
  'bg-mtd-red',
  'bg-mtd-yellow',
  'bg-mtd-blue',
  'bg-mtd-orange',
  'bg-mtd-pink',
  'bg-mtd-green'
]

const ascii = (a: string): number => a.charCodeAt(0)

const genRandomColour = (charityID: string): string => {
  // This is to get a deterministic colour for a charity.
  // So needs to return the same colour each time.
  // These are fallbacks if a charity has no explicit colour set.
  // Idea is, add up the ascii values in the ID. Module length colours, and pick that one.
  // Should be random enough, but it's not as obvious as which letter the charity starts with
  // or something.
  const someNumber = charityID.split('').reduce((acc, c) => acc + ascii(c), 0)
  return additionalColors[someNumber % additionalColors.length]
}

const Logo: FC<{ charityID: string, charityName: string }> = ({ charityID, charityName }) => {
  const [showFallback, setShowFallback] = useState(false)

  return <>
    {showFallback && <h2 className="font-bold text-center text-white sm:text-lg text-md">{charityName}</h2>}
    {!showFallback && <img className="w-28 sm:w-44 max-h-14 sm:max-h-28"
                           src={`/assets/charities/${charityID ?? ''}/logo_white.svg`}
                           alt={charityName}
                           onError={() => {
                             setShowFallback(true)
                           }} />}
  </>
}

export const CharityCard: FC<{ charityID: string, charityName: string, onClick: () => void }> = ({ charityID, charityName, onClick }) => {
  const charityColor = CHARITY_COLOURS[charityID ?? ''] ?? ''
  const style = charityColor !== '' ? { backgroundColor: charityColor } : {}
  const additionalColor = charityColor === '' ? genRandomColour(charityID) : ''

  return <div
    className={`${additionalColor} rounded-xl shadow-lg hover:shadow-none p-6 flex gap-2 sm:flex-col sm:items-center sm:text-center justify-between sm:justify-around cursor-pointer items-center sm:h-64 transform duration-300 ease-out hover:translate-y-1`}
    onClick={onClick}
    style={style}
  >
    <Logo charityID={charityID} charityName={charityName} />
    <button
      data-test-id={MytennightsTestId.charityCardSelectButton_ + charityID}
      className="px-6 py-3 text-white border-2 border-white rounded-full sm:hover:text-mtd-blue-800 sm:text-mtd-blue-900 sm:bg-white hover:border-mtn-blue-200 hover:text-mtn-blue-200">SELECT
    </button>
  </div>
}
