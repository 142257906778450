export class Navigation {
  static home (): string {
    return '/'
  }

  static selectCharity (): string {
    return '/donation/charity'
  }

  static selectCauses (): string {
    return '/donation/causes'
  }

  static payment (): string {
    return '/donation/payment'
  }
}
