import { Variant } from '@lib/components'
import { GivematchShareLinks } from '@shamaazi/mytennights'
import { type FC } from 'react'
import { useParams } from 'react-router-dom'
import { MTDHeader } from '~/components'

const ShareOptions: FC = () => {
  const { shareCode } = useParams() as { shareCode: string }

  return <>
    <MTDHeader overlay />
    <div className=" grid grid-rows-fr-auto">
      <main className="max-w-full py-4">
        <GivematchShareLinks shareCode={shareCode} isDashboard={false} platform={Variant.mtd} />
      </main>
    </div>
  </>
}

export default ShareOptions
