import { CampaignSetup, getCampaignDays } from '@shamaazi/mytennights'
const isLive = window.location.hostname.endsWith('mytendays.com')

export const isCampaignOpen = (): boolean => {
  if (!isLive) {
    return true
  }

  const daysTillCampaign = CampaignSetup.defaultDhulHijjahStartDate.diffNow('days')
  if (daysTillCampaign.days > CampaignSetup.daysToUnlockUI) { return false }

  const days = getCampaignDays(CampaignSetup.defaultDhulHijjahStartDate, 0)

  return days.some(d => d.isOpen)
}
