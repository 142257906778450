import { useState } from 'react'

import styles from './index.module.scss'
import { ComponentsTestId } from "@lib/testing";

export default ({ copyContent, children }) => {
  const [displayTooltip, setDisplayTooltip] = useState(false)

  const copy = (copyText, onSuccess) => {
    const body = document.getElementsByTagName('body')[0]
    const tempInput = document.createElement('INPUT')

    body.appendChild(tempInput)
    tempInput.setAttribute('value', copyText)
    tempInput.select()
    const result = document.execCommand('copy')
    body.removeChild(tempInput)

    if (result === true) {
      onSuccess()
    }
  }

  return <div
    data-clipboard-text={copyContent}
    data-test-id={ComponentsTestId.inviteLinkCopyButton}
    onClick={() => copy(copyContent, () => setDisplayTooltip(true))}
    onMouseOut={() => setTimeout(x => {
      setDisplayTooltip(false)
    }, 2000)}
    onTouchEnd={() => setTimeout(x => {
      setDisplayTooltip(false)
    }, 2000)}>

    {children}

    <div className={displayTooltip ? '' : 'hidden'}>
      <div className={styles.tooltip}>
        <div className={styles.tooltipLabel}>Copied!</div>
      </div>
    </div>
  </div>
}
