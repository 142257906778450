import { type FC } from 'react'
import { EmailXL, Page } from '@lib/components'
import { MTDHeader } from '~/components'

export const Contact: FC = () => {
  return <main>
    <MTDHeader overlay />
    <section className="bg-top bg-cover min-h-600 clouds">
      <Page skinny className="px-4 py-24 font-medium text-mtd-blue-900 min-h-600 md:min-h-screen">
        <h1 className="text-2xl font-bold md:text-4xl">
          Need some help?
        </h1>
        <p className="mt-2 md:text-xl md:mt-4">
          We've made some updates to our contact channels. If you have any inquiries or need assistance, you can reach us via email.
          <br />
          Here's how you can get in touch.
        </p>

        <div className="bg-mtd-blue-900 my-8 h-0.5 md:my-12"></div>

        <div className="flex items-center">
          <span className="inline-block text-white rounded-full bg-mtd-blue-900">
            <EmailXL />
          </span>
          <div className="flex-1 mt-2 ml-4 md:ml-8">
            <p><strong>Email us</strong> for donor inquiries.</p>
            <p className="mt-2 font-bold"><a href="mailto:contact@mytendays.com">contact@mytendays.com</a></p>
          </div>
        </div>
        <div className="mt-8 md:flex md:items-start">
          <span className="inline-block rounded-full bg-mtn-blue-500">
            <EmailXL />
          </span>
          <div className="mt-2 md:mt-0 md:flex-1 md:ml-8">
            <p><strong>Email us</strong> for charity inquiries.</p>
            <p className="mt-2 font-bold md:mt-4"><a href="mailto:contact@shamaazi.com">contact@shamaazi.com</a></p>
          </div>
        </div>
        <div className="">
          <p className="mt-10 md:mt-10 text-sm md:text-lg">Our opening hours are Monday to Friday from 9:00 AM to 5:00 PM, every day.</p>
        </div>
      </Page>
    </section>
  </main>
}
